.toast-container {
  max-width: 270px;
  padding: 0;
  right: 0;
  bottom: 20px;
  z-index: 11000;
}

.toast-container > div {
  font-family: "Rubik";
  font-weight: 600;
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.67;
  padding: 20px 35px 20px 15px;
  margin-bottom: 15px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  align-items: center;
}

.toast-container > div > div {
  margin-left: 15px;
  margin-right: auto;
}

.toast-container .success {
  background-color: #40c1d4;
}

.toast-container .success:before {
  content: "";
  display: block;
  background: url("../components/images/toast-success.svg");
  background-size: 100% 100%;
  width: 38px;
  height: 35px;
  min-width: 38px;
  min-height: 35px;
}

.toast-container .warning {
  background-color: #f89f78;
}

.toast-container .warning:before {
  content: "";
  display: block;
  background: url("../components/images/toast-warning.svg");
  background-size: 100% 100%;
  width: 38px;
  height: 35px;
  min-width: 38px;
  min-height: 35px;
}

.toast-container .error {
  background-color: #f8788a;
}

.toast-container .error:before {
  content: "";
  display: block;
  background: url("../components/images/toast-error.svg");
  background-size: 100% 100%;
  width: 38px;
  height: 35px;
  min-width: 38px;
  min-height: 35px;
}

.toast-container .info {
  background-color: #40c1d4;
}

.toast-container .info:before {
  content: "";
  display: block;
  background: url("../components/images/toast-success.svg");
  background-size: 100% 100%;
  width: 38px;
  height: 35px;
  min-width: 38px;
  min-height: 35px;
}

.Toastify__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
